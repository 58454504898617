<template>
  <div class="school_view">
    <BreadCrumb :items="breadCrumb">
      <template v-slot:controls>
        <button class="btn btn-sm btn-success" @click="$router.push({name: 'bke-school-new'})" title="Add a new school"><AddNewIcon /> Add New School</button>
      </template>
    </BreadCrumb>
    <div class="row">
      <div class="col">
        <SchoolPreview :school="schoolAsArray" :busy="schoolLoading">
          <template v-slot:controls>
            <button class="btn btn-sm btn-primary ml-2" :disabled="!school" @click="$router.push({name:'bke-school-edit', params:{school_id}})" title="Edit this school">Edit</button>
            <button class="btn btn-sm btn-danger ml-2" :disabled="!school" @click="deleteSchool" title="Delete this school">Delete</button>
          </template>
        </SchoolPreview>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewIcon from 'mdi-vue/PlaylistPlus';
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import getSchoolMixin from '@/mixins/getschool';
import SchoolPreview from './components/SchoolPreview.vue';

export default {
  name: 'BackendViewSchool',

  mixins: [getSchoolMixin],
  components: { BreadCrumb, AddNewIcon, SchoolPreview },

  data() {
    return {
      school_id: this.$route.params.school_id,
      schoolLoading: false,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'School List', to: { name: 'bke-school-list' } },
        { text: this.school_id || 'View', active: true },
      ];
    },
  },

  methods: {
    deleteSchool() {
      this.$bvModal.msgBoxConfirm('This school will be deleted. Are you sure?', {
        buttonSize: 'sm', centered: true, okVariant: 'danger', okTitle: 'Yes', cancelTitle: 'No',
      })
        .then((reply) => {
          if (reply === true) {
            this.schoolLoading = true;
            this.$store.dispatch('school/deleteSchools', [this.school_id]).then(() => {
              this.$router.push({ name: 'bke-school-list' });
            }).catch(() => {
              this.$toast.error('Failed to delete selected items. Try again later.');
            }).finally(() => {
              this.schoolLoading = false;
            });
          }
        })
        .catch(() => { /* ignore */ });
    },
  },

};
</script>
